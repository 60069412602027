import React, { useState } from "react";

import {
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
  Container,
} from "reactstrap";

import { useSelector } from "react-redux";
import classnames from "classnames";
import MyProfile from "./MyProfile";
import { Spinner } from "../../components/UI";
// import MyReferals from "./MyReferal";

const Profile = () => {
  const [activeTabJustify2, setactiveTabJustify2] = useState("25");

  const { isLoading } = useSelector((state) => state.auth);
  function toggleCustomJustified2(tab) {
    if (activeTabJustify2 !== tab) {
      setactiveTabJustify2(tab);
    }
  }

  if (isLoading)
    return (
      <>
        <div className="page-content">
          <Spinner />
        </div>
      </>
    );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Nav pills className="nav-justified bg-light">
            <NavItem className="waves-effect waves-light">
              <NavLink
                style={{ cursor: "default", fontSize: "18px" }}
                className={classnames({
                  active: activeTabJustify2 === "20",
                })}
                onClick={() => {
                  toggleCustomJustified2("25");
                }}
              >
                <span
                  className="d-block d-sm-none"
                  style={{ color: "#383838" }}
                >
                  {/* <i className="fas fa-home"></i> */}
                  Profile
                </span>
                <span
                  className="d-none d-sm-block"
                  style={{
                    color: "#383838",
                    fontSize: "24px",
                    fontWeight: "600",
                  }}
                >
                  Profile
                </span>
              </NavLink>
            </NavItem>
            {/* <NavItem className="waves-effect waves-light">
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTabJustify2 === "26",
                })}
                onClick={() => {
                  toggleCustomJustified2("26");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="far fa-user"></i>
                </span>
                <span className="d-none d-sm-block">My Referrals</span>
              </NavLink>
            </NavItem> */}
          </Nav>

          <TabContent activeTab={activeTabJustify2} className="p-3 text-muted">
            <TabPane tabId="25">
              <MyProfile />
            </TabPane>
            {/* <TabPane tabId="26"><MyReferals /></TabPane> */}
          </TabContent>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Profile;
