import React from "react";
import "./LoginNav.css";
import home from "../../assets/images/logo/home_2.png";
import { Link } from "react-router-dom";

function LoginNav() {
  return (
    <div>
      <nav className="navbar navbar-expand-md navbar-dark login__nav_background">
        <a className="navbar-brand" href="/login">
          <img src={home} alt="logo" className="login___logo__img" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#collapsibleNavbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="collapsibleNavbar"
        >
          <ul className="navbar-nav" style={{ marginRight: "75px" }}>
            <li className="nav-item nav__login__links font-montserrat">
              <Link className="nav-link login__link__text" to="/login">
                Log In
              </Link>
            </li>
            <li className="nav-item nav__login__links font-montserrat">
              <Link className="nav-link login__link__text" to="/faq">
                FAQ’s
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default LoginNav;
