import React from "react";
import { MoonPayBuyWidget } from "@moonpay/moonpay-react";
import { useSelector } from "react-redux";

const MoonPayWidget = ({
  visible,
  totalAmount,
  handleBuyTokenUsingFiat,
  tokenAmount,
}) => {
  const { userData } = useSelector((state) => state.auth);
  return (
    <>
      <MoonPayBuyWidget
        variant="overlay"
        baseCurrencyCode="usd"
        baseCurrencyAmount={totalAmount}
        defaultCurrencyCode="pol"
        // showOnlyCurrencies="po"
        visible={visible}
        lockAmount
        walletAddress={process.env.REACT_APP_ADMIN_WALLET_ADDRESS}
        onTransactionCompleted={handleBuyTokenUsingFiat}
        externalCustomerId={userData?.walletAddress}
        externalTransactionId={tokenAmount}
      />
    </>
  );
};

export default React.memo(MoonPayWidget);
