import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { useSelector } from "react-redux";
// import Web3 from "web3";
import AvailabilityDetails from "../../AvailabilityDetails/AvailabilityDetails";

import { Card } from "reactstrap";
// import Web3 from "web3";

const Doughnut = () => {
  const [saleStats, setSaleStats] = useState(0);

  const {
    userData,
    //  walletAddress,
    isLoading,
  } = useSelector((state) => state.auth);
  const { info } = useSelector((state) => {
    return state.ico;
  });

  const calculateStats = () => {
    if (
      info.currentPhase === "privateSale" ||
      (info.currentPhase === null && info.nextPhase === "privateSale")
    )
      return {
        totalAvailability: info.privateSale
          ? info.privateSale.quantity -
            (info.privateSale
              ? info.privateSale.quantity - info.privateSale.remaining
              : 0)
          : 0,
        totalBought: info.privateSale
          ? info.privateSale.quantity -
            info.privateSale.remaining -
            (userData && userData.tokenCount)
          : 0,
      };
    if (
      info.currentPhase === "closedSale" ||
      (info.currentPhase === null && info.nextPhase === "closedSale")
    )
      return {
        totalAvailability: info.closedSale
          ? info.closedSale.quantity +
            info.privateSale.quantity -
            (info.closedSale
              ? info.closedSale.quantity -
                info.closedSale.remaining +
                info.privateSale.quantity -
                info.privateSale.remaining
              : 0)
          : 0,
        totalBought: info.closedSale
          ? info.closedSale.quantity -
            info.closedSale.remaining +
            info.privateSale.quantity -
            info.privateSale.remaining -
            (userData && userData.tokenCount)
          : 0,
      };
    if (
      info.currentPhase === "publicSale" ||
      (info.currentPhase === null &&
        (info.nextPhase === "publicSale" || info.nextPhase === null))
    )
      return {
        totalAvailability: info.publicSale
          ? info.publicSale.quantity +
            info.closedSale.quantity +
            info.privateSale.quantity -
            (info.publicSale
              ? info.publicSale.quantity -
                info.publicSale.remaining +
                info.closedSale.quantity -
                info.closedSale.remaining +
                info.privateSale.quantity -
                info.privateSale.remaining
              : 0)
          : 0,
        totalBought: info.publicSale
          ? info.publicSale.quantity -
            info.publicSale.remaining +
            info.closedSale.quantity -
            info.closedSale.remaining +
            info.privateSale.quantity -
            info.privateSale.remaining -
            (userData && userData.tokenCount)
          : 0,
      };
  };

  useEffect(() => {
    if (info) setSaleStats(calculateStats());
  }, [info]);

  const getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} {b}: {c} ({d}%)",
      },
      legend: {
        orient: "horizontal",
        x: "right",

        data: ["Total Available", `Other's Allocation`, `My Allocation`],
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: ["#000000", "#505050", "#858585"],
      series: [
        {
          name: "",
          type: "pie",
          radius: ["60%", "70%"],
          avoidLabelOverlap: false,

          label: {
            normal: {
              show: false,
              position: "center",
            },

            emphasis: {
              show: true,
              textStyle: {
                fontSize: "15",
                fontWeight: "bold",
                color: "white",
              },
            },
          },

          labelLine: {
            normal: {
              show: false,
            },
          },

          data: [
            {
              value: info?.portfolio?.availableTokenAmount,
              name: "Total Available",
            },
            {
              value: info?.portfolio?.otherAllocation,
              name: "Other's Allocation",
            },
            {
              value: info?.portfolio?.myAllocation,
              name: "My Allocation",
            },
          ],
        },
      ],
    };
  };
  if (isLoading) {
    return (
      <>
        <Card>
          <div style={{ height: "100%" }} />
        </Card>
        <hr />
        <Card style={{ height: "100%" }}></Card>
      </>
    );
  }
  return (
    <React.Fragment>
      <ReactEcharts
        style={{ height: "500px", width: "80%" }}
        option={getOption()}
        // showLoading={isLoading ? true : false}
      />
    </React.Fragment>
  );
};
export default Doughnut;
