import React, { useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { AvField, AvForm } from "availity-reactstrap-validation";

import ReferralRewards from "./ReferralRewards";
import { inviteUserAction } from "../../store/actions";

const Referral = () => {
  const dispatch = useDispatch();
  const [referrerEmail, setReferrerEmail] = useState("");

  const handleInviteUser = () => {
    if (referrerEmail) {
      dispatch(
        inviteUserAction({
          data: { email: referrerEmail },
          setReferrerEmail,
        })
      );
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Row className="py-5 justify-content-start">
          {" "}
          <Col sm="12" md="8" lg="6">
            <div className="mb-4">
              <h6 className="header_text">Refer and Earn Rewards USDT...!</h6>
            </div>

            <AvForm className="d-flex mb-3" onValidSubmit={handleInviteUser}>
              <Col className="col-8 p-0">
                {" "}
                <AvField
                  name="email"
                  placeholder="Enter Referee Email"
                  type="text"
                  className="form-control"
                  value={referrerEmail}
                  onChange={(e) => setReferrerEmail(e.target.value)}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Email is required",
                    },
                    pattern: {
                      value:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                      errorMessage: "Invalid email address",
                    },
                  }}
                />
              </Col>
              <Col className="col-4 p-0">
                {" "}
                <Button
                  type="submit"
                  className="w-80 primary_btn"
                  disabled={!referrerEmail}
                >
                  Invite
                </Button>
              </Col>
            </AvForm>

            <div className="my-4">
              <h6 className="header_text title-2 kyc-status-msg font_grey">
                {" "}
                It's easy to earn rewards in simple steps
              </h6>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <ReferralRewards />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Referral;
