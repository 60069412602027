import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import LogoutModal from "../../components/Modal/LogoutModal";

// //Import Scrollbar
import SimpleBar from "simplebar-react";
import { useDispatch } from "react-redux";

// MetisMenu
import MetisMenu from "metismenujs";
import { Link, NavLink } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import "./Footer.css";

//i18n
import { withTranslation } from "react-i18next";
import { withRouter } from "../NonAuthLayout";
import { disconnectWalletCall } from "../../utils";

const SidebarContent = (props) => {
  const ref = useRef();
  const { connector } = useWeb3React();

  // const [isOpen, setIsOpen] = useState(false);
  const [isLogout, setLogout] = useState(false);

  const dispatch = useDispatch();

  const modalHandler = () => {
    setLogout(!isLogout);
  };

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props?.location?.pathname;

    new MetisMenu("#side-menu");
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i]?.pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props?.location?.pathname, activateParentDropdown]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  const disconnectWallet = useCallback(async () => {
    disconnectWalletCall(connector, dispatch);
  }, [connector]);

  return (
    <React.Fragment>
      <SimpleBar
        style={{ maxHeight: "100%" }}
        ref={ref}
        className="sidebar-menu-scroll"
      >
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("")} </li>
            <li
              className={window.location.pathname === "/dashboard" && "active"}
            >
              <Tooltip title="Dashboard" placement="right">
                <NavLink
                  to="/dashboard"
                  className="waves-effect"
                  activeclassname="active"
                >
                  <i className="uil-window-grid"></i>
                  <span>{props.t("Dashboard")}</span>
                </NavLink>
              </Tooltip>
            </li>

            <li className={window.location.pathname === "/buycoin" && "active"}>
              <Tooltip title="Buy Coin" placement="right">
                <NavLink
                  to="/buycoin"
                  className="waves-effect"
                  activeclassname="active"
                >
                  <i className="uil-shopping-cart-alt"></i>
                  <span>{props.t("Buy Coin")}</span>
                </NavLink>
              </Tooltip>
            </li>

            <li
              className={
                window.location.pathname === "/transaction" && "active"
              }
            >
              <Tooltip title="Transactions" placement="right">
                <NavLink
                  to="/transaction"
                  className="waves-effect"
                  activeclassname="active"
                >
                  <i className="uil-postcard"></i>
                  <span>{props.t("Transactions")}</span>
                </NavLink>
              </Tooltip>
            </li>

            <li
              className={window.location.pathname === "/referral" && "active"}
            >
              <Tooltip title="Referral" placement="right">
                <NavLink
                  to="/referral"
                  className="waves-effect"
                  activeclassname="active"
                >
                  <i className="uil-envelope-send"></i>
                  <span>{props.t("Referral")}</span>
                </NavLink>
              </Tooltip>
            </li>

            <li
              className={
                window.location.pathname === "/help-center" && "active"
              }
            >
              <Tooltip title="Help Center" placement="right">
                <NavLink
                  to="/help-center"
                  className="waves-effect"
                  activeclassname="active"
                >
                  <i className="uil-question-circle"></i>
                  <span>{props.t("Help Center")}</span>
                </NavLink>
              </Tooltip>
            </li>

            <li>
              <Tooltip title="Logout" placement="right">
                <div
                  to="#"
                  type="button"
                  className="waves-effect"
                  activeclassname="active"
                  onClick={() => {
                    modalHandler();
                  }}
                >
                  <i className="uil-sign-out-alt"></i>
                  <span>{props.t("Logout")}</span>
                </div>
              </Tooltip>
            </li>
          </ul>
        </div>
      </SimpleBar>
      {isLogout && (
        <LogoutModal
          openClose={isLogout}
          handleOpenClose={modalHandler}
          payload={{ title: "Are you sure you want to logout?" }}
          onSubmit={disconnectWallet}
        />
      )}
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
